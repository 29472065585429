import { Chip, Icon } from '@nike/eds'

interface ChipsProps {
  values: string[]
  deleteChip?: (value: string) => void
}

export const Chips = ({ values, deleteChip }: ChipsProps) => {
  const renderChips = () => {
    return values.map((valueOfChip, index) => {
      if (valueOfChip === '') { return null }
      return <Chip
        as="button"
        className='mt-5 mr-2'
        key={index}
        disabled={deleteChip === undefined}
        onClick={() => { deleteChip && deleteChip(valueOfChip) }}
        afterSlot={deleteChip ? <Icon name='Close' /> : <></>}
      >
        {valueOfChip}
      </Chip>
    })
  }

  return (
    <>{renderChips()}</>
  )
}
