import { Button, Icon, TextField } from '@nike/eds'
import React, { useState } from 'react'

interface BulkParametersStepProps {
  nextStepThresholds: (thresholds: string, skipThresholds: boolean) => void
  previousStep: () => void
}

export const FailureThresholdStep = ({ nextStepThresholds, previousStep }: BulkParametersStepProps) => {
  const [failureThreshold, setFailureThreshold] = useState('1')

  return (
    <div className="stepwizard-grid">
      <h2 className="text-2xl font-bold mb-4">Set failure thresholds for all actions</h2>
        <div className="form-grid">
            <div key={'failureThresholdDiv'} className="m-4 p-6 bg-white rounded shadow md:w-[300px]">
                <TextField
                    className="w-full"
                    id={'failureThresholdText'}
                    value={failureThreshold}
                    onChange={e => { setFailureThreshold(e.target.value) }}
                    label={'Failure threshold'}
                />
            </div>
        </div>
        <div className='button-grid'>
            <Button onClick={() => { previousStep() }}><Icon name="CaretLeft" /></Button>
            <div className={'flex flex-col gap-2'}>
              <Button onClick={() => { nextStepThresholds(failureThreshold, true) }} color="primary"><Icon name="FastForwardFilled" />Apply</Button>
              <Button onClick={() => { nextStepThresholds(failureThreshold, false) }} color="primary"><Icon name="PlayFilled" />Manual</Button>
            </div>
        </div>
    </div>
  )
}
