import { mainSplitApi } from 'redux/store/mainSplitApi'
import { ActionType, type ActionDTO, type TransitionDTO, type Workflow, type WorkflowDTO, type Action } from 'types'
import { appendCsv, removeCsv } from 'utils/FileHelper'

const fromWorkflowDTO = (workflowDto: WorkflowDTO): Workflow => {
  try {
    const { id, name, version, instanceCategories, globalParameters, description, payloadFile, transitions, executionGroups } = workflowDto
    if (id === null) throw new Error('Workflow id is null')
    const userPayloadFile = removeCsv(payloadFile)
    const actions = transitions.map((transition) => {
      const parameters: Record<string, any> = {}

      Object.entries(transition.action.parameters).forEach(([parameterKey, parameterValue]) => {
        if (!parameterKey.startsWith('_')) {
          parameters[parameterKey] = parameterValue
        }
      })

      const extractionFilename: string = transition.action.parameters._extractionFilename !== undefined
        ? removeCsv(transition.action.parameters._extractionFilename)
        : ''

      return {
        id: transition.action.id !== undefined ? transition.action.id : '',
        name: transition.action.name,
        description: transition.action.description,
        type: ActionType[transition.action.type as keyof typeof ActionType],
        username: transition.action.parameters._instanceUsername,
        url: transition.action.parameters._url,
        body: transition.action.parameters._body,
        httpMethod: transition.action.parameters._httpMethod,
        preRequestScript: transition.action.parameters._preRequestScript ? transition.action.parameters._preRequestScript : '',
        extractionFilename: extractionFilename?.split('.csv')[0] || '',
        extractionScript: transition.action.parameters._extractionScript ? transition.action.parameters._extractionScript : '',
        headers: transition.action.parameters._httpHeaders,
        parameters,
        inputFile: removeCsv(transition.action.inputFile)
      }
    })

    return { id, name, version, instanceCategories, globalParameters, description, payloadFile: userPayloadFile, actions, executionGroups }
  } catch (err) {
    console.log('error converting workflow from DTO', err, workflowDto)
    const errorId = workflowDto.id !== null ? workflowDto.id : ''
    return {
      id: errorId,
      name: workflowDto.name,
      version: -1,
      instanceCategories: [],
      globalParameters: {},
      payloadFile: '',
      actions: [],
      description: 'Error converting workflow from DTO, check console for details',
      executionGroups: []
    }
  }
}

const toWorkflowDTO = (workflow: Workflow): WorkflowDTO => {
  const { id, name, version, instanceCategories, globalParameters, description, payloadFile, actions, executionGroups } = workflow
  const idToSubmit = id !== '' ? id : null
  let i = 0

  // In case user enters .csv himself, check on this too
  const appendedPayloadFile = appendCsv(payloadFile)
  const transitions: TransitionDTO[] = actions.map((action: Action) => {
    const parameters: Record<string, any> = {}

    Object.entries(action.parameters).forEach(([parameterKey, parameterValue]) => {
      if (!parameterKey.startsWith('_')) {
        parameters[parameterKey] = parameterValue
      }
    })

    if (action.username !== undefined) { parameters._instanceUsername = action.username }
    parameters._url = action.url
    parameters._body = action.body
    parameters._httpHeaders = action.headers
    parameters._httpMethod = action.httpMethod
    if (action.preRequestScript.trim() !== '') {
      parameters._preRequestScript = action.preRequestScript.trim()
    }
    if (action.extractionFilename.trim() !== '') {
      parameters._extractionFilename = appendCsv(action.extractionFilename.trim())
    }
    if (action.extractionScript.trim() !== '') {
      parameters._extractionScript = action.extractionScript.trim()
    }

    const actionDto: ActionDTO = {
      id: action.id,
      name: action.name,
      description: action.description,
      type: ActionType[action.type],
      parameters,
      inputFile: appendCsv(action.inputFile)
    }

    const transition: TransitionDTO = {
      source: { name: 'State' + i.toString(), type: i === 0 ? 'BEGIN' : 'INTERMEDIATE' },
      target: { name: 'State' + (i + 1).toString(), type: i + 1 >= actions.length ? 'END' : 'INTERMEDIATE' },
      action: actionDto,
      event: { name: action.name + 'Event' },
      condition: { type: 'JAVASCRIPT', script: 'true' }
    }

    i++
    return transition
  })

  return {
    id: idToSubmit,
    name,
    version,
    instanceCategories,
    description,
    globalParameters,
    payloadFile: appendedPayloadFile,
    transitions,
    executionGroups
  }
}

export const workflowApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkflows: builder.query<Workflow[], void>({
      query: () => '/workflows',
      transformResponse: (response: WorkflowDTO[]) => response.map(fromWorkflowDTO),
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Workflow' as const, id })), 'Workflow']
          : ['Workflow']
    }),
    getWorkflow: builder.query<Workflow, string>({
      query: (workflowId) => `/workflows/${workflowId}`,
      transformResponse: (response: WorkflowDTO) => fromWorkflowDTO(response),
      providesTags: (result, error, arg) =>
        [{ type: 'Workflow', id: arg }]
    }),
    createWorkflow: builder.mutation<Workflow, Workflow>({
      query: (workflow) => ({
        url: '/workflows',
        method: 'POST',
        body: toWorkflowDTO(workflow)
      }),
      transformResponse: (response: WorkflowDTO) => fromWorkflowDTO(response),
      async onQueryStarted ({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: createdPayload } = await queryFulfilled
          dispatch(workflowApi.util.updateQueryData('getWorkflows', undefined, (draftWorkflows) => {
            draftWorkflows.push({ ...createdPayload })
          }))
        } catch {}
      }
    }),
    updateWorkflow: builder.mutation<Workflow, Workflow>({
      query: (workflow) => ({
        url: `/workflows/${workflow.id}`,
        method: 'PUT',
        body: toWorkflowDTO(workflow)
      }),
      transformResponse: (response: WorkflowDTO) => fromWorkflowDTO(response),
      invalidatesTags: (result, error, arg) =>
        [{ type: 'Workflow', id: arg.id }]
    }),
    deleteWorkflow: builder.mutation<void, string>({
      query: (workflowId) => ({
        url: `/workflows/${workflowId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) =>
        [{ type: 'Workflow', id: arg }]
    })
  })
})

export const {
  useGetWorkflowsQuery,
  useGetWorkflowQuery,
  useCreateWorkflowMutation,
  useUpdateWorkflowMutation,
  useDeleteWorkflowMutation
} = workflowApi
