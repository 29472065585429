export const hasExtension = (file: File, extension: string): boolean => {
  return file.name.split('.').pop()?.toLowerCase() === extension.toLowerCase()
}

export const isSmallerThanOrEqual = (file: File, maxMegabytes: number): boolean => {
  const megabytes = file.size / 1048576
  return megabytes <= maxMegabytes
}

export const isLargerThan = (file: File, minBytes: number): boolean => {
  return file.size > minBytes
}

export const appendCsv = (filename: string): string => {
  if (filename === '' || filename.endsWith('.csv')) {
    return filename
  } else {
    return `${filename}.csv`
  }
}

export const removeCsv = (filename: string): string => {
  if (filename == null) {
    return ''
  } else if (filename.endsWith('.csv')) {
    return filename.split('.csv')[0]
  } else {
    return filename
  }
}
