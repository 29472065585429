import { Button, Icon, TextField } from '@nike/eds'
import React from 'react'

interface ActionStepProps {
  nextStep: () => void
  previousStep: () => void
  updateParameters: (parameters: Record<string, string>) => void
  parameters: Record<string, string>
  previousStepDisabled: boolean
}

export const GlobalParametersStep = ({ nextStep, previousStep, updateParameters, parameters, previousStepDisabled }: ActionStepProps) => {
  const [globalParameters, setGlobalParameters] = React.useState<Record<string, string>>(parameters)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    setGlobalParameters(prevGlobalParameters => { return { ...prevGlobalParameters, [id]: value } })
    updateParameters({ ...globalParameters, [id]: value })
  }

  return (
    <div className="stepwizard-grid">
      <h2 className="text-2xl font-bold mb-4">Global Parameters</h2>
      <div className="form-grid">
        {Object.entries(globalParameters).map(([key, value], index: number) => {
          return (<div key={index.toString()} className="m-4 p-6 bg-white rounded shadow md:w-[300px]">
            <TextField
              className="w-full"
              id={key}
              value={value}
              onChange={onChange}
              label={key}
            />
          </div>)
        })}
      </div>
      <div className='button-grid'>
        <Button onClick={previousStep} color="primary" disabled={previousStepDisabled}><Icon name="CaretLeft" /></Button>
        <Button onClick={nextStep} color="primary"><Icon name="CaretRight" /></Button>
      </div>
    </div>
  )
}
