import { Button, Icon } from '@nike/eds'
import { ActionType, type WorkflowExecution } from 'types'

interface EndStepProps {
  execution: WorkflowExecution
  previousStep: () => void
  ticketNumber: string
  submit: () => Promise<void>
}

export const EndStep = ({ execution, previousStep, ticketNumber, submit }: EndStepProps) => {
  return (
    <div className="stepwizard-grid">
      <h1 className="text-2xl font-bold mb-4">Execution Summary</h1>
      <div className="summary-grid bg-white shadow rounded p-4 max-w-xl w-full">
        <h2 className="text-xl font-bold mb-2">Ticket: {ticketNumber}</h2>
        <h3 className="text-lg font-bold mt-4 mb-2">Global Parameters</h3>
        <ul className="list-disc list-inside">
          {execution.globalParameters && Object.entries(execution.globalParameters)
            .map(([key, value]) => (
              <li key={key}>{key}: {value}</li>
            ))
          }
        </ul>
        {execution.actions.map((action, index) => (
          <div key={index}>
            <h3 className="text-lg font-bold mt-4 mb-2">Action {index + 1}: {action.name}</h3>
            {Object.keys(action.parameters)
              .filter(([key, value]) => (!key.startsWith('_')))
              .length > 0 && <>
                <h4>Parameters</h4>
                <ul className="list-disc list-inside">
                  {action.type === ActionType.API_CALL
                    ? Object.entries(action.parameters)
                      .filter(([key, value]) => (!key.startsWith('_')))
                      .map(([key, value]) => (
                        <li key={key}>{key}: {value}</li>
                      ))
                    : <li>File: {action.inputFile}</li>
                  }
                </ul>
              </>}
          </div>
        ))}
        <br />
        <div className='button-grid'>
          <Button onClick={previousStep}><Icon name="CaretLeft" /></Button>
          <Button onClick={() => { submit().then() }}><Icon name="Play" />Submit</Button>
        </div>
      </div>
    </div>
  )
}
