import { Button, Icon, TextField } from '@nike/eds'
import React from 'react'
import { type Action } from 'types'

interface ActionStepProps {
  nextStep: () => void
  previousStep: () => void
  updateAction: (action: Action) => void
  action: Action
  previousStepDisabled: boolean
}

const failureThresholdKey = '_failureThreshold'

export const ActionStep = ({ nextStep, previousStep, updateAction, action, previousStepDisabled }: ActionStepProps) => {
  const paramsToShow = action.type === 'BULK_API_CALL'
    ? Object.entries(action.parameters).filter(([key]) => key === failureThresholdKey)
    : Object.entries(action.parameters)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    updateAction({ ...action, parameters: { ...action.parameters, [id]: value } })
  }

  return (
    <div className="stepwizard-grid">
      <h2 className="text-2xl font-bold mb-4">{action.name}</h2>
      <div className="form-grid">
        {paramsToShow.map(([key, value], index: number) => {
          const label = key === failureThresholdKey ? 'Failure Threshold' : key
          return (<div key={index.toString()} className="m-4 p-6 bg-white rounded shadow md:w-[300px]">
            <TextField
              className="w-full"
              id={key}
              value={value}
              onChange={onChange}
              label={label}
            />
          </div>)
        })}
      </div>
      <div className='button-grid'>
        <Button onClick={previousStep} color="primary" disabled={previousStepDisabled}><Icon name="CaretLeft" /></Button>
        <Button onClick={nextStep} color="primary"><Icon name="CaretRight" /></Button>
      </div>
    </div>
  )
}
