import { Button, Icon, Spinner } from '@nike/eds'
import { useGetInstanceProfilesQuery, useGetInstancesCategoriesQuery } from 'api/instance'
import {
  useCreateWorkflowMutation,
  useDeleteWorkflowMutation,
  useGetWorkflowsQuery,
  useUpdateWorkflowMutation
} from 'api/workflow'
import { hasExecutionRights, hasRole } from 'auth/authentication'
import { AuthRole } from 'auth/const'
import { ConfirmationModalTrigger } from 'components/confirmation-modal-trigger'
import { FullScreenOverlay } from 'components/fullscreen-overlay'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { dispatch, useAppSelector, type RootState } from 'redux/store'
import { ActionType, type Action, type Workflow } from 'types'
import { deepEqual } from 'utils/ComparisonTools'

import { ActionList, ActionModal, WorkflowHeader } from './components'
import { GlobalParameterModal } from './components/GlobalParameterModal'
import { WorkflowMode } from './types'

const userSelector = (state: RootState) => state.user

// To be renamed to 'workflow', current 'workflow' page should be renamed to 'workflow-overview'
export const WorkflowEditor = () => {
  const { data: workflows, isLoading: isLoadingWorkflows } = useGetWorkflowsQuery()
  const { data: instanceProfiles, isLoading: isLoadingProfiles } = useGetInstanceProfilesQuery()
  const { data: instanceCategories, isLoading: isLoadingCategories } = useGetInstancesCategoriesQuery()
  const [createWorkflow, { isLoading: isCreatingWorkflow, isSuccess: isSuccessCreate }] = useCreateWorkflowMutation()
  const [updateWorkflow, { isLoading: isUpdatingWorkflow, isSuccess: isSuccessUpdate }] = useUpdateWorkflowMutation()
  const [deleteWorkflow, { isLoading: isDeletingWorkflow }] = useDeleteWorkflowMutation()
  const { user } = useAppSelector(userSelector)
  const location = useLocation()
  const isCloneRoute = location.pathname.endsWith('/clone')

  const navigate = useNavigate()
  const { workflowId } = useParams()
  const [localWorkflow, setLocalWorkflow] = useState<Workflow>({ id: '', name: '', version: 0, instanceCategories: [], globalParameters: {}, description: '', payloadFile: '', actions: [], executionGroups: [] })
  const [originalWorkflow, setOriginalWorkflow] = useState<undefined | Workflow>(undefined)
  const [actionModalOpen, setActionModalOpen] = useState(false)
  const [parameterModalOpen, setParameterModalOpen] = useState(false)
  const [workflowMode, setWorkflowMode] = useState<WorkflowMode>(WorkflowMode.VIEW)
  const [modalMode, setModalMode] = useState<'new' | 'edit' | undefined>(undefined)
  const submittingWorkflowChanges = isCreatingWorkflow || isUpdatingWorkflow || isDeletingWorkflow
  const [selectedAction, setSelectedAction] = useState<Action | undefined>(undefined)
  const [showActions, setShowActions] = useState<boolean>(false)
  const [fileNames, setFileNames] = useState<string[]>([])
  let workflowErrors: string[] = []
  const [showWorkflowErrors, setShowWorkflowErrors] = useState<boolean>(false)
  const [, setActionsWithInvalidInputFile] = useState<string[]>([])

  useEffect(() => {
    if (isLoadingWorkflows || !workflows) {
      return
    }
    if (workflowId !== undefined) {
      const foundWorkflow = workflows.find(wf => wf.id === workflowId)
      if (foundWorkflow) {
        setShowActions(true)
        if (isCloneRoute) {
          const clonedWorkflow: Workflow = { ...foundWorkflow, id: '', name: `${foundWorkflow.name} (copy)` }
          setLocalWorkflow(clonedWorkflow)
          setOriginalWorkflow(undefined)
          setWorkflowMode(WorkflowMode.NEW)
        } else {
          setLocalWorkflow(foundWorkflow)
          setOriginalWorkflow(foundWorkflow)
          setWorkflowMode(WorkflowMode.VIEW)
        }
      }
    } else {
      setWorkflowMode(WorkflowMode.NEW)
    }
  }, [workflows, workflowId, location.pathname])

  useEffect(() => {
    if (isSuccessCreate || isSuccessUpdate) {
      dispatch(showSnackbar('Successfully saved workflow', 'success'))
      navigate('/workflows')
    }
  }, [isSuccessCreate, isSuccessUpdate])

  useEffect(() => {
    const notBlankFilenames = []
    if (localWorkflow.payloadFile !== '') notBlankFilenames.push(localWorkflow.payloadFile)

    notBlankFilenames.push(
      ...localWorkflow.actions
        .filter(action => action.extractionFilename !== '')
        .map(action => action.extractionFilename)
    )

    setFileNames(notBlankFilenames)
  }, [localWorkflow.actions, localWorkflow.payloadFile])

  useEffect(() => {
    if (fileNames.length > 0) {
      const invalidInputFiles = getActionNamesWithInvalidInputFile(localWorkflow, fileNames)
      if (invalidInputFiles.length > 0) dispatch(showSnackbar(`Don't forget to update following action${invalidInputFiles.length > 1 ? 's' : ''} '${invalidInputFiles.join(', ')}'`, 'warning'))
    }
  }, [fileNames])

  const updateLocalWorkflow = (newData: Partial<Workflow>) => {
    if (newData == null) return
    setShowActions(true)
    setLocalWorkflow(prevWorkflow => ({
      ...prevWorkflow,
      ...newData
    }))
  }

  const updateActions = (action: Action, origActionName?: string) => {
    // Helper function to check if the action name exists
    const actionNameAlreadyExists = (name: string) => localWorkflow.actions.some(existingAction => existingAction.name === name)

    // In case of new mode, check if the new name already exists
    if (modalMode === 'new') {
      if (actionNameAlreadyExists(action.name)) {
        dispatch(showSnackbar(`Action with name '${action.name}' already exists in workflow`, 'error'))
        return
      }
      setLocalWorkflow(prevWorkflow => ({
        ...prevWorkflow,
        actions: [...prevWorkflow.actions, action]
      }))
    } else if (modalMode === 'edit') {
      // If the name is changed and new name already exists, show error
      if (origActionName !== action.name && actionNameAlreadyExists(action.name)) {
        dispatch(showSnackbar(`Action with name '${action.name}' already exists in workflow`, 'error'))
        return
      } else if (origActionName) {
        setActionsWithInvalidInputFile(prevActions => {
          return prevActions.map(item => item === origActionName ? action.name : origActionName)
        })
      }

      setLocalWorkflow(prevWorkflow => ({
        ...prevWorkflow,
        actions: localWorkflow.actions.map(existingAction =>
          existingAction.name === origActionName ? action : existingAction
        )
      }))
    }
    setActionModalOpen(false)
  }

  const updateGlobalParameters = (parameters: Record<string, string>) => {
    setLocalWorkflow(prevWorkflow => ({
      ...prevWorkflow,
      globalParameters: parameters
    }))
    setParameterModalOpen(false)
  }

  const onActionDelete = (id: string) => {
    setLocalWorkflow(prevWorkflow => ({
      ...prevWorkflow,
      actions: prevWorkflow.actions.filter(action => action.id !== id)
    }))
  }

  const reorderActions = (actions: Action[]) => {
    setLocalWorkflow(prevWorkflow => ({
      ...prevWorkflow,
      actions
    }))
  }

  const handleActionEdit = (action: Action) => {
    setSelectedAction(action)
    setModalMode('edit')
    setActionModalOpen(true)
  }

  const handleActionNew = () => {
    setSelectedAction({ id: '', name: '', description: '', type: ActionType.API_CALL, username: '', url: '', body: '', httpMethod: 'GET', preRequestScript: '', extractionFilename: '', extractionScript: '', headers: {}, parameters: {}, inputFile: '' })
    setModalMode('new')
    setActionModalOpen(true)
  }

  const submitWorkflow = () => {
    if (workflows !== undefined) {
      if (workflows.some(wf => wf.id !== localWorkflow.id && wf.name === localWorkflow.name)) {
        dispatch(showSnackbar('Workflow name already exist!', 'error'))
      } else {
        (!originalWorkflow) ? createWorkflow(localWorkflow) : updateWorkflow(localWorkflow)
      }
    }
  }

  const onDeleteConfirmed = () => {
    if (workflowId !== undefined) {
      deleteWorkflow(workflowId).unwrap()
        .then(() => {
          dispatch(showSnackbar('Workflow deleted', 'success'))
          navigate('/workflows')
        })
        .catch(() => {
          dispatch(showSnackbar('Error deleting workflow...', 'error'))
        })
    }
  }

  const hasMissingRequiredFields = () => {
    workflowErrors = []
    if (localWorkflow.name === '') {
      workflowErrors.push('Workflow name')
    }
    if (localWorkflow.description === '') {
      workflowErrors.push('Workflow description')
    }
    if (localWorkflow.actions.length === 0) {
      workflowErrors.push('Action')
    }
    const invalidInputFiles = getActionNamesWithInvalidInputFile(localWorkflow, fileNames)
    if (invalidInputFiles.length > 0) workflowErrors.push(`Input file for action${invalidInputFiles.length > 1 ? 's' : ''} '${invalidInputFiles.join(', ')}'`)

    return workflowErrors.length > 0
  }

  const isSaveDisabled = () => {
    const basicRequired = hasMissingRequiredFields()
    if (originalWorkflow !== undefined) {
      return basicRequired || deepEqual(originalWorkflow, localWorkflow)
    } else {
      return basicRequired
    }
  }

  const showEditButton = () => {
    if (isSaveDisabled()) {
      return <Icon
        name="Edit"
        size="l"
        className={'cursor-pointer'}
        // New does not have access to the edit button
        onClick={() => {
          setWorkflowMode(workflowMode === WorkflowMode.VIEW ? WorkflowMode.EDIT : WorkflowMode.VIEW)
        }}
      />
    } else {
      return <ConfirmationModalTrigger
        onTrigger={() => { submitWorkflow() }}
        title={'Save workflow'}
        message={'You have unsaved changes, do you want to save this workflow?'}
        icon={'Edit'}
        iconClass={''}
        confirmText={'Save'}
      />
    }
  }

  return (
    <>
      <div className="flex justify-between md:w-1/2 w-full m-auto mt-2">
        <h1 className="pt-5 eds-type--title-1">
          Workflow
        </h1>
        {(workflowMode === WorkflowMode.VIEW || workflowMode === WorkflowMode.EDIT) &&
          <div className={'mt-5'}>
            {hasExecutionRights(user) && (
              <Icon
                className={'ml-8 cursor-pointer'}
                name="Play"
                size="l"
                onClick={() => { navigate(`/workflow-execution/${workflowId!}`) }}
              />
            )}
            {hasRole(AuthRole.WORKFLOW_WRITE, user) && (
              <>
                <Icon
                  name="CopyPaste"
                  size="l"
                  className={'cursor-pointer'}
                  // New does not have access to the edit button
                  onClick={() => { navigate(`/workflow-editor/${workflowId!}/clone`) }}
                />
                {showEditButton()}
                <ConfirmationModalTrigger
                  onTrigger={() => { onDeleteConfirmed() }}
                  title={'Delete workflow'}
                  message={'Are you sure you want to delete this workflow?'}
                  icon={'Delete'}
                  iconClass={''}
                  confirmText={'Delete'}
                />
              </>
            )}
          </div>
        }
      </div>
      <div className="flex justify-center items-center">
        <div style={{ marginTop: '2rem' }} className="w-4/5 bg-white eds-elevation--2">
          {isLoadingProfiles || isLoadingWorkflows || isLoadingCategories
            ? (
              <div className={'flex justify-center m-8'}>
                <Spinner size="large" />
              </div>
              )
            : (<>
              <WorkflowHeader workflow={localWorkflow} updateWorkflow={updateLocalWorkflow} workflowMode={workflowMode} workflowFileNames={fileNames} instanceCategories={instanceCategories ?? []} />
              <hr />

              {showActions && (
                <div className={'flex flex-col justify-center items-center mb-6'}>
                  <div className="m-4 bg-gray-200 border border-gray-300 rounded-lg shadow flex md:w-[600px]">
                    <div className="flex-col flex-grow p-6 relative cursor-pointer" onClick={() => { setParameterModalOpen(true) }}>
                      <span className={'text-xl font-bold overflow-text-1'}>Global Parameters</span>
                    </div>
                  </div>
                  {localWorkflow.actions.length === 0 && <p className=" my-4 text-lg">No actions added yet</p>}
                  <ActionList actions={localWorkflow.actions}
                    reorder={reorderActions}
                    handleActionClick={handleActionEdit}
                    viewMode={workflowMode === WorkflowMode.VIEW}
                    onActionDelete={onActionDelete} />
                  {workflowMode !== WorkflowMode.VIEW &&
                    <Button className={'items-center'} onClick={() => { handleActionNew() }}>Add action</Button>
                  }
                  {actionModalOpen && selectedAction != null && instanceProfiles !== undefined &&
                    <ActionModal
                      onClose={() => { setActionModalOpen(false) }}
                      action={selectedAction}
                      updateActions={updateActions}
                      modalMode={modalMode}
                      workflowMode={workflowMode}
                      workflowFileNames={fileNames}
                      profileCredentials={instanceProfiles}
                    />
                  }
                  {parameterModalOpen &&
                    <GlobalParameterModal
                      onClose={() => { setParameterModalOpen(false) }}
                      parameters={localWorkflow.globalParameters}
                      workflowMode={workflowMode}
                      updateParameters={updateGlobalParameters}
                    />
                  }
                </div>
              )}

              {localWorkflow.actions.length > 0 && workflowMode !== WorkflowMode.VIEW &&
                <>
                  <div className={'w-100 pb-20'}>
                    <Button className='!mr-6 !mb-4 float-right'
                      onClick={() => { submitWorkflow() }}
                      disabled={isSaveDisabled()}
                      onPointerOverCapture={() => { setShowWorkflowErrors(true) }}
                      onPointerLeave={() => { setShowWorkflowErrors(false) }}
                    >
                      Save workflow
                    </Button>
                  </div>
                  {showWorkflowErrors && workflowErrors.length > 0 && (
                    <div className='!mr-6 !mb-4 float-right'>
                      <p style={{ color: 'red' }}>
                        {workflowErrors.join(', ')} {workflowErrors.length === 1 ? 'is' : 'are'} required
                      </p>
                    </div>
                  )}
                </>
              }
            </>
              )}
        </div>
      </div>
      {submittingWorkflowChanges && <FullScreenOverlay />}
    </>
  )
}

export const isUniqueFilename = (filename: string, oldFilename: string, fileNames: string[]) => {
  if (filename === oldFilename) {
    // If the filename is the same as the old, there should be 1 because nothing has changed and the filename already exists in the list
    return fileNames.filter(fn => fn === filename).length === 1
  } else {
    // If the filename is different, there should be 0, because for uniqueness, the filename should not exist in the list
    return fileNames.filter(fn => fn === filename).length === 0
  }
}

const getActionNamesWithInvalidInputFile = (workflow: Workflow, fileNames: string[]) => {
  const invalidInputFiles: string[] = []
  workflow.actions.forEach(action => {
    if (action.type === ActionType.BULK_API_CALL) {
      if (!fileNames.includes(action.inputFile)) {
        invalidInputFiles.push(action.name)
      }
    }
  })

  return invalidInputFiles
}
