
export const MawmIcon = (
  <svg
    className="eds-icon"
    width="25"
    height="25"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 58.9385 58.0077 H 47.0539 L 74.0539 22.0308 V 37.5385 L 58.9385 58.0077 Z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 74.0538 58.0077 H 61.9384 L 74.0538 42.3846 V 58.0077 Z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 43.2923 58.0077 H 31.4077 L 74.0538 0.938477 V 16.4462 L 43.2923 58.0077 Z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 27.5314 58.0077 H 15.6468 L 42.6929 22.0308 V 37.5385 L 27.5314 58.0077 Z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M 11.9077 58.0077 H 0 L 42.6923 0.938477 V 16.4462 L 11.9077 58.0077 Z" fill="currentColor" transform=" matrix(1 0 0 1 0 0) " stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
)
