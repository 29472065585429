import { hasSufficientRole, type AuthRole } from 'auth/const'

class User {
  groups: string[]
  roles: AuthRole[]

  constructor (groups: string[], roles: AuthRole[]) {
    this.groups = groups
    this.roles = roles
  }

  canUse (role: AuthRole): boolean {
    return this.roles.filter(ur => hasSufficientRole(ur, role)).length > 0
  }

  has (role: AuthRole): boolean {
    return this.roles.filter(r => r === role).length > 0
  }

  hasAnyRole (): boolean {
    return this.roles.filter(role => this.canUse(role)).length > 0
  }
}

export default User
