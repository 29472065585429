import { Button, Select, TextField, TextGroup } from '@nike/eds'
import { Chips } from 'components/chips'
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import { type OauthCredentials } from 'types'

import { CustomTextField } from './CustomTextField'

const authorizationGrantTypes = [
  { value: 'password', label: 'password' },
  { value: 'client_credentials', label: 'client_credentials' }
]

interface CredentialsConfigProps {
  credentialsName: string
  credentials: OauthCredentials
  saveConfigCredentials: (oldName: string, newName: string | null, updatedCreds: OauthCredentials) => void
  deleteCredentials: (credName: string) => void
  errors: Record<string, string> | undefined
  setErrors: Dispatch<SetStateAction<Record<string, string> | undefined>>
  isViewMode: boolean
}

export const CredentialsConfig = ({ credentialsName, credentials, saveConfigCredentials, deleteCredentials, errors, setErrors, isViewMode }: CredentialsConfigProps) => {
  if (credentials === undefined) return <>Please select a tab</>
  const [localName, setLocalName] = useState<string>('')
  const [localCredentials, setLocalCredentials] = useState<OauthCredentials>(credentials)
  const [newProfileScope, setNewProfileScope] = useState<string>('')

  const [credentialsNameError, setCredentialsNameError] = useState<string>('')

  useEffect(() => {
    if (localName !== '') {
      setCredentialsNameError('')
      if (localCredentials !== credentials) saveConfigCredentials(localName, null, localCredentials)
    }
  }, [localCredentials])

  useEffect(() => {
    setLocalCredentials(credentials)
    setLocalName(credentialsName)
  }, [credentials, credentialsName])

  const onChangeInputField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    onChangeCredentials(id, value)
  }

  const addProfileScope = () => {
    if (newProfileScope === '') return
    setLocalCredentials(prevCred => {
      return {
        ...prevCred,
        scopes: [...prevCred.scopes, newProfileScope]
      }
    })
    setNewProfileScope('')
  }
  const deleteProfileScope = (value: string) => {
    // delete scope from list in profiles
    const newScopes = localCredentials.scopes.filter((scope) => scope !== value)
    setLocalCredentials(prevCred => {
      return {
        ...prevCred,
        scopes: newScopes
      }
    })
  }
  const onChangeCredentials = (id: string, value: string) => {
    if (errors !== undefined && Object.keys(errors).includes(id)) {
      setErrors((prevErrors) => {
        if (prevErrors !== undefined && Object.keys(prevErrors).includes(id)) {
          const { [id]: _, ...rest } = prevErrors
          return {
            ...rest
          }
        }
      })
    }

    setLocalCredentials(prevCred => {
      return {
        ...prevCred,
        [id]: value
      }
    })
  }

  return (
    <div className="flex flex-wrap gap-8">
      <CustomTextField
        width={'half'} id={'credentialsName'}
        label='Credentials name'
        value={localName}
        readOnly={isViewMode}
        onChange={(e) => {
          setCredentialsNameError('')
          setLocalName(e.target.value)
        }}
        onBlur={(e) => {
          if (e.target.value === '') {
            setCredentialsNameError('Credentials name is required')
            return
          }
          saveConfigCredentials(credentialsName, e.target.value, localCredentials)
        }}
        hasErrors={credentialsNameError !== ''}
        errorMessage={credentialsNameError}
      />

      <h1 className={'w-full eds-type--title-4'}>Authorization</h1>
      <div style={{ width: 'calc(33% - 30px)' }}>
        <Select
          id='authorizationGrantType'
          label='Authorization grant type'
          defaultValue={authorizationGrantTypes.find((type) => type.value === localCredentials.authorizationGrantType)}
          options={authorizationGrantTypes}
          isDisabled={isViewMode}
          onChange={(e) => {
            e && onChangeCredentials('authorizationGrantType', e.value)
          }}
        />
      </div>
      <CustomTextField width={'half'} id={'tokenUri'}
                       label='Token uri'
                       value={localCredentials.tokenUri}
                       readOnly={isViewMode}
                       onChange={onChangeInputField}
                       hasErrors={errors && 'tokenUri' in errors}
                       errorMessage={errors && errors.tokenUri}/>

      <div className='gap-0.5 w-full'>
        <h1 className={'w-full eds-type--title-5'}>Scopes</h1>
        <TextGroup className={'w-full'}>
            <Chips
                values={localCredentials.scopes}
                deleteChip={deleteProfileScope}
            />
        </TextGroup>
        <div className='flex gap-4'>
            <TextField
                className={'mt-0 pt-0'}
                id='newScope'
                label=''
                value={newProfileScope}
                onChange={e => {
                  setNewProfileScope(e.target.value)
                }}
            />
            <Button className='self-end'
                    onClick={addProfileScope}
            >
                Add
            </Button>
        </div>
      </div>
      <h1 className={'w-full eds-type--title-5'}>OAuth client secrets</h1>
      <CustomTextField width={'half'} id={'clientId'}
                       label={'Client ID key'}
                       value={localCredentials.clientId}
                       readOnly={isViewMode}
                       onChange={onChangeInputField}
                       hasErrors={errors && 'clientId' in errors}
                       errorMessage={errors && errors.clientId}/>
      <CustomTextField width={'half'} id={'clientSecret'}
                       label={'Client secret key'}
                       value={localCredentials.clientSecret}
                       readOnly={isViewMode}
                       onChange={onChangeInputField}
                       hasErrors={errors && 'clientSecret' in errors}
                       errorMessage={errors && errors.clientSecret}/>

      {localCredentials.authorizationGrantType === 'password' && (
        <>
          <h1 className={'w-full eds-type--title-5'}>User credentials</h1>
          <CustomTextField width={'half'} id={'username'}
                           label={'Username key'}
                           value={localCredentials.username}
                           readOnly={isViewMode}
                           onChange={onChangeInputField}
                           hasErrors={errors && 'username' in errors}
                           errorMessage={errors && errors.username}/>
          <CustomTextField width={'half'} id={'password'}
                           label={'Password key'}
                           value={localCredentials.password}
                           readOnly={isViewMode}
                           onChange={onChangeInputField}
                           hasErrors={errors && 'password' in errors}
                           errorMessage={errors && errors.password}/>
        </>
      )}

      {!isViewMode && (
          <div style={{
            width: 'calc(100% - 16px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}>
              {/* {error && <p className="text-red-500 !mr-6 !mb-4 float-right">{error}</p>} */}
              <Button onClick={() => {
                deleteCredentials(credentialsName)
              }}>Delete credentials</Button>
          </div>
      )}
    </div>
  )
}
