export enum AuthRole {
  STANDARD = 'STANDARD',
  READ = 'READ',
  WORKFLOW_EXECUTION = 'WORKFLOW_EXECUTION',
  WORKFLOW_WRITE = 'WORKFLOW_WRITE',
  ADMIN = 'ADMIN',
  SUPPORT = 'SUPPORT'
}

const roleHierarchy: Record<AuthRole, number> = {
  [AuthRole.STANDARD]: 1,
  [AuthRole.READ]: 2,
  [AuthRole.WORKFLOW_EXECUTION]: 3,
  [AuthRole.WORKFLOW_WRITE]: 4,
  [AuthRole.ADMIN]: 5,
  [AuthRole.SUPPORT]: 6
}

export const hasSufficientRole = (role: AuthRole, targetRole: AuthRole): boolean => {
  return roleHierarchy[role] !== undefined && roleHierarchy[role] >= roleHierarchy[targetRole]
}
