import { Button, Icon, Select } from '@nike/eds'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { dispatch } from 'redux/store'
import { type SelectOption } from 'types'

interface InstanceStepProps {
  nextStep: () => void
  previousStep: () => void
  setInstanceId: (instanceId: string) => void
  instanceId: string
  instances: SelectOption[]
  profiles: SelectOption[]
  profile: string
  setProfile: (profile: string) => void
  workflowName: string
}

export const InstanceStep = ({ nextStep, previousStep, setInstanceId, instanceId, instances, profile, profiles, setProfile, workflowName }: InstanceStepProps) => {
  const handleClickNext = () => {
    if (instanceId.length === 0) {
      dispatch(showSnackbar('Please select an instance id', 'error'))
    } else if (profile.length === 0) {
      dispatch(showSnackbar('Please select a profile', 'error'))
    } else {
      nextStep()
    }
  }

  return (
    <div className="stepwizard-grid">
      <h2 className="text-2xl font-bold mb-4">{workflowName}</h2>
      <div className="form-grid">
        <div key={'instanceDiv'} className="m-4 p-6 bg-white rounded shadow flex flex-col items-start justify-between w-full md:w-[300px]">
          <div className='w-full'>
            <Select id={'instanceId'}
              className='w-full'
              label={'Instance'}
              onChange={(selected) => { selected !== null && setInstanceId(selected.value) }}
              options={instances}
            />
            <br />
            <Select id={'profileId'}
              className='w-full'
              label={'Profile'}
              onChange={(selected) => { selected !== null && setProfile(selected.value) }}
              options={profiles}
            />
          </div>
        </div>
      </div>
      <div className='button-grid'>
        <Button onClick={previousStep} color="primary"><Icon name="CaretLeft" /></Button>
        <Button onClick={handleClickNext} color="primary"><Icon name="CaretRight" /></Button>
      </div>
    </div>
  )
}
