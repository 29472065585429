import { Spinner } from '@nike/eds'
import { useSubmitExecutionMutation } from 'api/execution'
import { useGetAllInstancesQuery } from 'api/instance'
import { useGetWorkflowQuery } from 'api/workflow'
import { FullScreenOverlay } from 'components/fullscreen-overlay'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import StepWizard from 'react-step-wizard'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { dispatch } from 'redux/store'
import {
  type Action,
  type SelectOption,
  type Workflow,
  type WorkflowExecution,
  type Instance
} from 'types'

import { ActionStep, EndStep, InstanceStep, PayloadFileStep, TicketEntryStep, GlobalParametersStep, FailureThresholdStep } from './components'
import './stepwizard.css'
import './transitions.css'

export const NewExecution = () => {
  const { data: instances, isLoading: isInstancesLoading } = useGetAllInstancesQuery()
  const { workflowId } = useParams()
  const { data: workflow, isLoading: isWorkflowsLoading } = useGetWorkflowQuery(workflowId!)
  const [submitExecution,
    {
      isLoading: isSubmittingExecution,
      isSuccess: isSuccessSubmittingExecution,
      isError: isErroredSubmittingExecution,
      error: submitError
    }] = useSubmitExecutionMutation()
  const [wizard, setWizard] = useState<any>(null)
  const [execution, setExecution] = useState<WorkflowExecution>(
    { actions: [], description: '', id: '', workflowId: '', name: '', payloadFile: '', parameterFile: null, globalParameters: {}, delimiter: '' }
  )
  const [uploadPayloadFile, setUploadPayloadFile] = useState<boolean>(false)
  const [globalParameters, setGlobalParameters] = useState<Record<string, string>>({})
  const [ticketNumber, setTicketNumber] = useState<string>('')
  const [instanceId, setInstanceId] = useState<string>('')
  const [selectedProfile, setSelectedProfile] = useState<string>('')
  const [skipThresholds, setSkipThresholds] = useState<boolean>(false)
  const navigate = useNavigate()

  const [instanceOptions, setInstanceOptions] = useState<SelectOption[]>([])
  const [profileOptions, setProfileOptions] = useState<SelectOption[]>([])

  useEffect(() => {
    if (workflow !== undefined) {
      setExecution(toExecution(workflow))
      setUploadPayloadFile(workflow.payloadFile !== '')
      setGlobalParameters(workflow.globalParameters ?? {})
    }
  }, [workflow])

  useEffect(() => {
    if (instances !== undefined) {
      const mappedOptions: SelectOption[] = [
        ...instances.map((item: Instance) => ({ value: item.id, label: item.name }))
      ]
      setInstanceOptions(mappedOptions)
    }
  }, [instances])

  useEffect(() => {
    if (instances !== undefined && instanceId !== '') {
      const selectedInstance = instances.find((instance: Instance) => instance.id === instanceId)
      if (selectedInstance) {
        const mappedOptions: SelectOption[] = [
          ...Object.keys(selectedInstance.profiles).map((item: any) => ({ value: item, label: item }))
        ]
        setProfileOptions(mappedOptions)
      }
    }
  }, [instanceId])

  // Used to update internal actions object of execution, replacing if found
  const updateAction = (action: Action) => {
    setExecution(prevExecution => ({
      ...prevExecution,
      actions: prevExecution.actions.map(existingAction =>
        existingAction.name === action.name ? action : existingAction
      )
    }))
  }

  const updateGlobalParameters = (parameters: Record<string, string>) => {
    setExecution(prevExecution => ({
      ...prevExecution,
      globalParameters: parameters
    }))
  }

  const updateThresholdsAndNextStep = (threshold: string, skipThreshold: boolean) => {
    setSkipThresholds(skipThreshold)
    setExecution(prevExecution => ({
      ...prevExecution,
      actions: prevExecution.actions.map(action => {
        if (action.type === 'BULK_API_CALL') {
          return {
            ...action,
            parameters: {
              ...action.parameters,
              _failureThreshold: threshold
            }
          }
        } else {
          return action
        }
      })
    }))
    wizard?.nextStep()
  }

  const anyBulkActions = () => {
    return execution.actions.some(action => action.type === 'BULK_API_CALL')
  }

  const updateExecutionWithUploadParameters = (file: File, delimiter: string) => {
    setExecution(prevExecution => ({
      ...prevExecution,
      parameterFile: file,
      delimiter
    }))
  }

  useEffect(() => {
    if (isSuccessSubmittingExecution) {
      dispatch(showSnackbar('Successfully created execution for workflow', 'success'))
        .then(() => { navigate('/active') })
    }
  }, [isSuccessSubmittingExecution])

  useEffect(() => {
    if (isErroredSubmittingExecution) {
      dispatch(showSnackbar((submitError as Error).message, 'error'))
    }
  }, [isErroredSubmittingExecution])

  const submit = async () => {
    await submitExecution({ execution, instanceId, selectedProfile, ticketNumber })
  }

  const getActionsWithParameters = () => {
    const parameterActions: Action[] = []
    execution.actions
      .filter(action => !skipThresholds || action.type !== 'BULK_API_CALL')
      .forEach((action) => {
        const paramsToCheck = skipThresholds
          ? Object.keys(action.parameters).filter(key => key !== '_failureThreshold')
          : Object.keys(action.parameters)
        if (paramsToCheck.length > 0) {
          parameterActions.push(action)
        }
      })
    return parameterActions
  }

  // Dynamically generate steps based on workflow selected
  const steps = useCallback(() => [
    ...(uploadPayloadFile
      ? [
        <PayloadFileStep
          key="bulk-parameters"
          nextStep={wizard?.nextStep}
          filename={execution.payloadFile}
          uploadFile={updateExecutionWithUploadParameters} />
        ]
      : []),
    ...(anyBulkActions()
      ? [<FailureThresholdStep
                key="failure-threshold-step"
                nextStepThresholds={updateThresholdsAndNextStep}
                previousStep={wizard?.previousStep} />]
      : []),
    ...(Object.entries(globalParameters).length > 0
      ? [
        <GlobalParametersStep
          key="global-parameters"
          nextStep={wizard?.nextStep}
          previousStep={wizard?.previousStep}
          updateParameters={updateGlobalParameters}
          parameters={globalParameters}
          previousStepDisabled={!uploadPayloadFile} />
        ]
      : []),
    ...getActionsWithParameters()
      .map((action, index) => (
        <ActionStep
          key={index}
          nextStep={wizard?.nextStep}
          previousStep={wizard?.previousStep}
          updateAction={updateAction}
          action={action}
          previousStepDisabled={!anyBulkActions() && Object.entries(globalParameters).length === 0}
        />
      )),
    <TicketEntryStep key={'ticketEntryStep'}
      nextStep={wizard?.nextStep}
      previousStep={wizard?.previousStep}
      setTicketNumber={setTicketNumber}
      ticketNumber={ticketNumber}
      workflowName={execution.name}
      previousDisabled={!anyBulkActions() && getActionsWithParameters().length === 0} />,
    <InstanceStep key={'instanceStep'}
      nextStep={wizard?.nextStep}
      previousStep={wizard?.previousStep}
      setInstanceId={setInstanceId}
      instanceId={instanceId}
      workflowName={execution.name}
      instances={instanceOptions}
      profiles={profileOptions}
      profile={selectedProfile}
      setProfile={setSelectedProfile} />,
    <EndStep
      key={'endStep'}
      previousStep={wizard?.previousStep}
      execution={execution}
      ticketNumber={ticketNumber}
      submit={async () => { await submit() }} />
  ], [execution, ticketNumber, instanceId, instanceOptions, profileOptions, selectedProfile, uploadPayloadFile, skipThresholds])

  return (
    <>
      <div className="md:w-1/2 w-full m-auto mt-2">
        <h1 className="pt-5 eds-type--title-1">
          New execution
        </h1>
      </div>
      <div className="flex justify-center items-center">
        <div style={{ marginTop: '2rem' }} className="w-3/5 bg-gray-200 eds-elevation--2">
          {isInstancesLoading || isWorkflowsLoading
            ? (
              <div className='flex justify-center py-10 mb-4'>
                <Spinner size='large' />
              </div>
              )
            : (<>
              <div className="justify-center items-center m-4">
                <div className={'w-full'}>
                  <h1 className="text-2xl font-bold mb-4">{execution.name}</h1>
                </div>
                <p className="text-lg mb-4">{execution.description}</p>
                <hr />
              </div>

              <StepWizard transitions={
                  {
                    enterRight: 'animated fadeInRight',
                    enterLeft: 'animated fadeInLeft'
                  }
              } instance={setWizard}>
                {steps()}
              </StepWizard>
            </>)
          }
        </div>
      </div>
      {isSubmittingExecution && <FullScreenOverlay />}
    </>
  )
}

const toExecution = (workflow: Workflow): WorkflowExecution => {
  return {
    id: crypto.randomUUID(),
    workflowId: workflow.id,
    name: workflow.name,
    description: workflow.description,
    payloadFile: workflow.payloadFile,
    actions: toExecutionActions(workflow.actions),
    globalParameters: workflow.globalParameters,
    parameterFile: null,
    delimiter: ''
  }
}

const toExecutionActions = (actions: Action[]): Action[] => {
  return actions.map(action => {
    if (action.type === 'BULK_API_CALL') {
      return {
        ...action,
        parameters: {
          ...action.parameters,
          _failureThreshold: action.parameters._failureThreshold || '1'
        }
      }
    } else {
      return action
    }
  })
}
