import { Button, Icon } from '@nike/eds'
import { CSVFileUploader } from 'components/file-uploader/CSVFileUploader'
import React, { useState } from 'react'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { dispatch } from 'redux/store'

interface BulkParametersStepProps {
  nextStep: () => void
  filename: string
  uploadFile: (file: File, delimiter: string) => void
}

export const PayloadFileStep = ({ nextStep, uploadFile, filename }: BulkParametersStepProps) => {
  const [valid, setValid] = useState(false)

  const handleInvalidFile = () => {
    setValid(false)
    dispatch(showSnackbar('Invalid file', 'error'))
  }
  const handleValidFile = (file: File, delimiter: string) => {
    setValid(true)
    uploadFile(file, delimiter)
  }

  return (
    <div className="stepwizard-grid">
      <h2 className="text-2xl font-bold mb-4">Upload CSV</h2>
      <p className={'mb-4'}>Filename: &quot;{filename}&quot;</p>
      <div className="form-grid">
        <div key={'bulkapiDiv'} className="m-4 p-6 bg-white rounded shadow flex flex-col items-start justify-between w-full md:w-[400px]">
          <CSVFileUploader onValid={handleValidFile} onInvalid={handleInvalidFile} />
        </div>
      </div>
      <div className='button-grid'>
        <Button disabled={true}><Icon name="CaretLeft" /></Button>
        <Button onClick={nextStep} color="primary" disabled={!valid}><Icon name="CaretRight" /></Button>
      </div>
    </div>
  )
}
