import { Button, Icon, TextField } from '@nike/eds'
import React from 'react'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { dispatch } from 'redux/store'

interface TicketEntryStepProps {
  nextStep: () => void
  previousStep: () => void
  setTicketNumber: (ticketNumber: string) => void
  ticketNumber: string
  workflowName: string
  previousDisabled: boolean
}

export const TicketEntryStep = ({ nextStep, previousStep, setTicketNumber, ticketNumber, workflowName, previousDisabled }: TicketEntryStepProps) => {
  const handleClickNext = () => {
    if (ticketNumber.length === 0) {
      dispatch(showSnackbar('Ticket number is required', 'error'))
    } else {
      nextStep()
    }
  }
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setTicketNumber(value)
  }

  return (
    <div className="stepwizard-grid">
      <h2 className="text-2xl font-bold mb-4">{workflowName}</h2>
      <div className="form-grid">
        <div key={'ticketDiv'} className="m-4 p-6 bg-white rounded shadow flex flex-col items-start justify-between w-full md:w-[300px]">
          <TextField
            className="w-full"
            id={'ticketNumber'}
            onChange={onChange}
            label={'Ticket number (SNOW/Jira)'}
            required={true}
          />
        </div>
      </div>
      <div className='button-grid'>
        <Button onClick={previousStep} color="primary" disabled={previousDisabled}><Icon name="CaretLeft" /></Button>
        <Button onClick={handleClickNext} color="primary" disabled={ticketNumber.length === 0}><Icon name="CaretRight" /></Button>
      </div>
    </div>
  )
}
