import { type AuthState } from '@okta/okta-auth-js'
import User from 'types/User'

import { AuthRole } from './const'

export const initUser = (authState: AuthState, userRoles: string[]) => {
  if (authState?.accessToken) {
    const groups = authState?.accessToken?.claims.groups
    const arrayGroups: string[] = Object.values(groups).map(gr => {
      return gr as string
    })

    return new User(arrayGroups, getAuthRole(userRoles))
  }
}

const getAuthRole = (roles: string[]): AuthRole[] => {
  const userRoles = new Set<AuthRole>()
  roles.forEach(role => {
    const authRole = AuthRole[role as keyof typeof AuthRole]
    if (authRole) userRoles.add(AuthRole[role as keyof typeof AuthRole])
  })
  return Array.from(userRoles)
}

export const hasRole = (target: AuthRole, user: User | undefined) => {
  if (user) {
    return user.canUse(target)
  } else {
    return false
  }
}

export const hasExecutionRights = (user: User | undefined) => {
  if (user) {
    return user.canUse(AuthRole.WORKFLOW_EXECUTION) || user.has(AuthRole.STANDARD)
  } else {
    return false
  }
}
