import { Button, Select, TextArea, TextField, TextGroup } from '@nike/eds'
import { Chips } from 'components/chips'
import { useEffect, useRef, useState } from 'react'
import { type Workflow } from 'types'

import { isUniqueFilename } from '../WorkflowEditor'

export interface WorkflowHeaderProps {
  workflow: Workflow
  updateWorkflow: (newData: Partial<Workflow>) => void
  workflowMode: 'view' | 'new' | 'edit'
  workflowFileNames: string[]
  instanceCategories: string[]
}

export const WorkflowHeader = ({ workflow, updateWorkflow, workflowMode, workflowFileNames, instanceCategories }: WorkflowHeaderProps) => {
  const nameRef = useRef<HTMLInputElement>(null)
  const oldParamaterFile = workflow.payloadFile
  const [payloadFile, setPayloadFile] = useState('')
  const [payloadFileError, setPayloadFileError] = useState('')
  const [currentADGroups, setCurrentADGroups] = useState<string[]>([])
  const [newADGroup, setNewADGroup] = useState('')
  const [errorNewGroup, setErrorNewGroup] = useState('')

  useEffect(() => {
    if (workflow.executionGroups != null) {
      setCurrentADGroups(workflow.executionGroups)
    }
  }, [workflow.executionGroups])

  useEffect(() => {
    setPayloadFile(workflow.payloadFile)
  }, [workflow.payloadFile])

  // Autofocus on first field
  useEffect(() => {
    nameRef.current?.focus()
  }, [])

  const handleFilenameChange = (value: string) => {
    if (!isUniqueFilename(value, oldParamaterFile, workflowFileNames)) {
      setPayloadFileError('Filename already exists')
    } else {
      setPayloadFileError('')
    }
    if (value !== oldParamaterFile) {
      updateWorkflow({ payloadFile: value.trim() })
    }
  }

  const addInstanceCategories = (values: string[]) => {
    updateWorkflow({ instanceCategories: values })
  }

  const addADGroupChip = () => {
    if (newADGroup.trim() === '') {
      setErrorNewGroup('Group name cannot be empty')
      return
    }
    if (currentADGroups.includes(newADGroup)) {
      setErrorNewGroup('Group already exists')
      return
    }
    updateWorkflow({ executionGroups: [...currentADGroups, newADGroup] })
    setNewADGroup('')
  }

  const deleteADGroupChip = (group: string) => {
    updateWorkflow({ executionGroups: currentADGroups.filter(g => g !== group) })
  }

  return (
          <>
            {workflowMode !== 'view'
              ? (
                <div className="flex m-4 gap-8">
                  <div className='w-1/2'>
                      <TextField className={'mb-4'} id={'workflowName'} value={workflow.name} type="text"
                         label={'Workflow name *'} required={true} onChange={(e) => {
                           updateWorkflow({ name: e.target.value })
                         }}
                      ref={nameRef}/>
                      <TextArea className={'mb-4'} id={'descriptionArea'} minRows={3} value={workflow.description}
                        label={'Workflow description *'} required={true} onChange={(e) => {
                          updateWorkflow({ description: e.target.value })
                        }}/>
                      <TextField className="w-full mb-4"
                        id="payloadFileName"
                        value={payloadFile}
                        onChange={(e) => { setPayloadFile(e.target.value) }}
                        onBlur={(e) => { handleFilenameChange(e.target.value) }}
                        label="Parameter file name"
                        hasErrors={payloadFileError !== ''}
                        errorMessage={payloadFileError}
                        afterSlot={<span>.csv</span>}
                        required={false} />
                    </div>
                    <div className='w-1/2'>
                      <Select
                        id='instanceCategories'
                        label='Instance tags'
                        isMulti
                        value={workflow.instanceCategories && workflow.instanceCategories.length > 0 ? workflow.instanceCategories.map((category) => ({ label: category, value: category })) : []}
                        options={instanceCategories && instanceCategories.map((category) => ({ label: category, value: category }))}
                        onChange={(e) => { addInstanceCategories(e.map(item => item.value)) }}
                      />
                      <TextGroup>
                        <Chips
                          values={currentADGroups}
                          deleteChip={deleteADGroupChip}
                        />
                      </TextGroup>
                      <div className='flex auto place-items-center gap-4 mt-2'>
                        <TextField
                          id='newGroup'
                          label='New AD group'
                          value={newADGroup}
                          onChange={(e) => {
                            setErrorNewGroup('')
                            setNewADGroup(e.target.value)
                          }}
                          errorMessage={errorNewGroup}
                          hasErrors={errorNewGroup !== ''}
                        />
                        <Button className='self-end'
                          onClick={addADGroupChip}
                        >
                          Add
                        </Button>
                      </div>
                  </div>
                </div>
                )
              : (
                <div className="justify-center items-center m-4 w-7/8">
                  <h1 className="text-2xl font-bold mb-4">{workflow.name}</h1>
                  <p className={'text-lg mb-4 whitespace-pre-wrap'}>{workflow.description}</p>
                  <p className={'text-lg mb-4'}>{workflow.payloadFile}</p>
                  {workflow.instanceCategories && (
                    <div>
                      <Chips
                        values={workflow.instanceCategories}
                      />
                    </div>
                  )}
                </div>
                )}
        </>
  )
}
